import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import TopCard, { CustomCardAction } from "./TopCard";
import { useTranslation } from "react-i18next";
import { Overview, useApi } from "@blindspot/bspot-api-lib";
import useService from "../hooks/useService";
import { useRecoilValue } from "recoil";
import { apiClient } from "../state/atoms";

interface AttackErrCardProps {
  overview: Overview;
  loading: boolean;
}

export default function AttackErrCard({ overview, loading }: AttackErrCardProps) {
  const { t } = useTranslation("index", { keyPrefix: "cards" });

  return (
    <TopCard
      isLoading={loading}
      status="attack"
      overline={t("under_attack.mitigations.title")}
      h5={`${overview?.ongoing_attacks_count} ${t("under_attack.mitigations.mitigation", { count: overview?.ongoing_attacks_count })}`}
      icon={<ShieldOutlinedIcon color="error" fontSize="large" />}
    >
      <CustomCardAction linkToIdElement={{ text: t("common.show_all"), href: "#attack_history", color: "text.primary" }} />
    </TopCard>
  );
}
