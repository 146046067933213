import { RecoilRoot } from "recoil";
import React from "react";

import Main from "./Main";
import OpenIDGuard from "./providers/OpenIDGuard";

import "./styles/app.scss";
import CssBaseline from "@mui/material/CssBaseline";
import AppThemeProvider from "./theme/ThemeProvider";
import { SnackbarProvider } from "notistack";
import TenantProvider from "./providers/ServiceProvider";
import { Alert, Snackbar } from "@mui/material";
import useIsOffline from "./hooks/useIsOffline";
import Head from "./components/Print/Head";

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { POSTHOG_KEY, POSTHOG_API_HOST } from "./consts";

if (!!POSTHOG_KEY && !!POSTHOG_API_HOST) {
  posthog.init(
    POSTHOG_KEY,
    {
      api_host: POSTHOG_API_HOST,
    }
  )
};

function OfflineSnap() {
  const offline = useIsOffline();
  return (
    <Snackbar className="print-hide" open={offline} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
      <Alert variant="filled" severity="error">
        You are offline!
      </Alert>
    </Snackbar>
  );
}

export default function App() {
  return (
    <RecoilRoot>
      <AppThemeProvider>
        <OpenIDGuard>
          <CssBaseline />
          <React.StrictMode>
            <PostHogProvider client={posthog}>
              <TenantProvider>
                <SnackbarProvider maxSnack={4}>
                  <OfflineSnap />
                  <Head />
                  <Main />
                </SnackbarProvider>
              </TenantProvider>
            </PostHogProvider>
          </React.StrictMode>
        </OpenIDGuard>
      </AppThemeProvider>
    </RecoilRoot>
  );
}
