import { Box, Card, CardContent, Typography, alpha, CardActions, Skeleton } from "@mui/material";
import { useTheme, Link as MuiLink } from "@mui/material";
import Link from "../providers/router/Link";
import DotStatusPrefix from "./DotStatusPrefix";

interface TopCardProps {
  overline?: string;
  h5?: string | Array<string>;
  icon?: JSX.Element;
  dotStatuses?: Array<Status>;
  children: JSX.Element;
  status: "peace" | "normal" | "attack";
  isLoading?: boolean;
}
interface CustomCardActionProps {
  caption?: {
    color: string;
    text: string;
  };
  link?: {
    href: string;
    color: string;
    text: string;
  };
  linkToIdElement?: {
    href: string;
    color: string;
    text: string;
  };
}

export interface Status {
  name: string;
  state: "ok" | "err" | "warn";
}

export function CustomCardAction({ caption, link, linkToIdElement }: CustomCardActionProps) {
  return (
    <CardActions
      sx={{
        flexDirection: "column",
        alignItems: "flex-start",
        paddingX: "1.5rem",
        paddingBottom: "1rem",
        paddingTop: "0rem",
      }}
    >
      {caption && (
        <Typography variant="caption" style={{ color: caption.color }}>
          {caption.text}
        </Typography>
      )}
      {link && (
        <Link href={link.href} color={link.color} variant="overline">
          {link.text}
        </Link>
      )}
      {linkToIdElement && (
        <MuiLink href={linkToIdElement.href} color={linkToIdElement.color} variant="overline">
          {linkToIdElement.text}
        </MuiLink>
      )}
    </CardActions>
  );
}

export default function TopCard({ overline, h5, icon, children, status, dotStatuses, isLoading }: TopCardProps) {
  const theme = useTheme();

  return (
    <>
      <Card
        elevation={status === "normal" ? 1 : 0}
        sx={{
          height: "100%",
          border: 1,
          backgroundColor:
            status === "attack"
              ? alpha(theme.palette.error.main, 0.08)
              : status === "peace"
                ? "rgba(102, 187, 106, 0.08)"
                : "",
          borderColor: status === "normal" ? "divider" : status === "attack" ? "error.main" : "#66BB6A",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <CardContent sx={{ paddingX: "1.5rem", paddingY: "1rem" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
            <Box sx={{ maxWidth: "85%" }}>
              {overline && (
                <Typography variant="overline" style={{ color: alpha(theme.palette.text.secondary, 0.7) }}>
                  {overline}
                </Typography>
              )}
              {isLoading ? (
                <Skeleton width={180} height="32.016px" />
              ) : typeof h5 === "string" ? (
                <Typography variant="h5" style={{ color: theme.palette.text.primary }}>
                  {h5}
                </Typography>
              ) : typeof h5 === "object" ? (
                h5.map((h5One, i) => (
                  <Typography key={i} variant="h5" style={{ color: theme.palette.text.primary, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                    {h5One}
                  </Typography>
                ))
              ) : (
                <></>
              )}

              {dotStatuses ? (
                isLoading ? (
                  <Box sx={{ height: "1.5rem" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "0px",
                        marginBottom: "-6px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "8px",
                          height: "8px",
                          borderRadius: "5px",
                          backgroundColor: "divider",
                          marginRight: "10px",
                        }}
                      />
                      <Skeleton width="24px" />
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ height: "1.5rem", display: "flex", flexDirection: "column" }}>
                    {dotStatuses.map((status, i) => (
                      <DotStatusPrefix
                        sx={{ marginTop: "0px" }}
                        key={"status_" + i}
                        statusName={status.name}
                        status={status.state}
                      />
                    ))}
                  </Box>
                )
              ) : (
                <></>
              )}
            </Box>
            {icon && <Box sx={{ alignSelf: "flex-start" }}>{icon}</Box>}
          </Box>
        </CardContent>
        {children}
      </Card>
    </>
  );
}
