import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import TopCard, { CustomCardAction } from "./TopCard";
import { useTranslation } from "react-i18next";
import { Attack, Overview, useApi } from "@blindspot/bspot-api-lib";
import useService from "../hooks/useService";
import { useRecoilValue } from "recoil";
import { apiClient } from "../state/atoms";
import { Box } from "@mui/material";

interface AttackHistoryErrCardProps {
  overview: Overview;
  loading: boolean;
}

export default function AttackHistoryErrCard({ overview, loading }: AttackHistoryErrCardProps) {
  const { t } = useTranslation("index", { keyPrefix: "cards" });

  const len = overview?.ongoing_attacks_target_ips.length;

  const ip = len > 2
    ? [
      `${len} ${t("under_attack.destination.destination", { count: len })}`,
      overview?.ongoing_attacks_target_ips.join(", ")
    ]
    : overview?.ongoing_attacks_target_ips;

  return (
    <TopCard
      isLoading={loading}
      status="attack"
      overline={t("under_attack.destination.title")}
      h5={ip}
      icon={<WarningAmberOutlinedIcon color="error" fontSize="large" sx={{ alignSelf: "flex-start" }} />}
    >
      <CustomCardAction linkToIdElement={{ text: t("common.show_all"), href: "#attack_history", color: "text.primary" }} />
    </TopCard>
  );
}
