import { useTranslation } from "react-i18next";
import TopCard from "./TopCard";
import { Attack, Overview, useApi } from "@blindspot/bspot-api-lib";
import { formatPackets } from "../utils/formatPackets";
import { formatBits } from "../utils/formatBits";
import { useRecoilValue } from "recoil";
import { apiClient } from "../state/atoms";
import useService from "../hooks/useService";

interface ConnectionsErrCardProps {
  overview: Overview;
  loading: boolean;
}

export default function ConnectionsErrCard({ overview, loading }: ConnectionsErrCardProps) {
  const { t, i18n } = useTranslation("index", { keyPrefix: "cards" });

  const service = useService();
  const api = useRecoilValue(apiClient);

  return (
    <TopCard
      isLoading={loading}
      status="attack"
      overline={t("under_attack.peak_bandwidth.title")}
      h5={
        [formatBits(overview?.ongoing_attacks_bandwidth || 0, i18n.language, true) + "ps"]
      }
    >
      <></>
    </TopCard>
  );
}
